import { useHistory } from 'react-router-dom';
import { TTravelGuide } from 'shared/types/travelGuide.types';
import { OpacityContentCard } from 'shared/lib/OpacityContentCard/OpacityContentCard';
import { TRANSFORM_TRAVEL_GUIDE } from 'shared/constants/travelGuide.constants';
import { URL_FOR_IMG } from 'shared/constants/const';

type TTravelGuideCardProps = {
  travelGuide: TTravelGuide;
};

export const TravelGuideCard = ({ travelGuide }: TTravelGuideCardProps) => {
  const history = useHistory();

  const { id, name, type, photo, locationTags } = travelGuide;
  const photoSrc = `${URL_FOR_IMG}/${photo.path ? photo.path : null}`;

  const categoryLabel =
    type === 'Природная локация' ? TRANSFORM_TRAVEL_GUIDE.tags.flora.label : TRANSFORM_TRAVEL_GUIDE.tags.city.label;

  const openTravelGuide = () => {
    history.push(`/travel-guides/${name}`);
  };

  return (
    <OpacityContentCard className='travel-card' locationTags={locationTags}  srcImage={photoSrc} altImage='Дальний Таймыр' tag={categoryLabel}>
      <>
        <h3 className='travel-card__title'>{name}</h3>
        <button className='travel-card__btn' type='button' onClick={openTravelGuide}>
          Подробнее
        </button>
      </>
    </OpacityContentCard>
  );
};
