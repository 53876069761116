import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store';
import { TFiltersPartners } from 'shared/types/partner.types';

export const selectLoading = (state: TRootState) => state.partners.isLoading;
export const selectPartners = (state: TRootState) => state.partners.partners;
export const selectFiltersPartners = (state: TRootState) => state.partners.filtersPartners;

export const selectFilteredPartners = createSelector(
  selectPartners,
  (state: TRootState, filters: TFiltersPartners) => filters,
  (partners, filters) => {
    if (!filters.types.length) {
      return partners;
    }
    return partners?.filter((partner) => filters.types.includes(partner.tag));
  }
);

export const selectPartnerItem = createSelector(
  selectPartners,
  (state: TRootState, id: string | number) => id,
  (state: TRootState, name: string) => name,
  (partners, id, name) => {
    // Преобразуем id в число, если это строка
    const idNumber = typeof id === 'string' ? Number(id) : id;
    
    // Поиск партнера по id
    let partner = partners?.find((partner) => partner.id === idNumber);

    // Если партнер не найден по id, ищем по имени
    if (!partner && name) {
      partner = partners?.find((partner) => partner.name === name);
    }

    return partner || null;
  }
);

