import { useHistory } from 'react-router-dom';
import { TRANSFORM_TEXT, URL_FOR_IMG } from 'shared/constants/const';
import { declensionNoun } from 'helpers/declensionNoun';
import { TTour } from 'shared/types/tours.types';
import { ReactComponent as RubleIcon } from 'assets/images/common/ruble.svg';
import { getTextFromMinMaxValue } from 'helpers/getTextFromMinMaxValue';

type TTourCardProps = {
  tour: TTour;
  endSwipe?: () => void;
  isSwipe?: boolean;
};

export const TourCard = ({ tour, endSwipe, isSwipe }: TTourCardProps) => {
  const history = useHistory();

  const {
    minAge,
    maxAge,
    price,
    preview,
    productType,
    title,
    spring,
    summer,
    winter,
    autumn,
    shortDesc,
    minGroupSize,
    maxGroupSize,
    operator,
    photo,
  } = tour;
  const priceText = price.toLocaleString('ru-RU');
  const ageText = getTextFromMinMaxValue(minAge, maxAge, { key: 'age' });
  const groupText = getTextFromMinMaxValue(minGroupSize, maxGroupSize, { label: 'чел' });

  let seasonText = '';
  if ((summer && spring && winter && autumn) || (!summer && !spring && !winter && !autumn)) {
    seasonText = 'Круглый год';
  } else {
    const seasonArr = [];
    if (summer) {
      seasonArr.push(TRANSFORM_TEXT.season.SUMMER);
    }
    if (autumn) {
      seasonArr.push(TRANSFORM_TEXT.season.AUTUMN);
    }
    if (winter) {
      seasonArr.push(TRANSFORM_TEXT.season.WINTER);
    }
    if (spring) {
      seasonArr.push(TRANSFORM_TEXT.season.SPRING);
    }
    seasonText = seasonArr.join(' ');
  }

  const nameOperator = operator?.shortName || `${operator?.lastName} ${operator?.firstName} ${operator?.firstName}`;
  const photoOperator = !!photo?.path ? `${URL_FOR_IMG}/${photo.path}` : null;

  const openTour = () => {
    if (!isSwipe) {
      history.push(`/tours/${tour.title}`);
    }
  };

  return (
    <div className='tour-card' key={tour.id} onClick={openTour} onMouseDown={endSwipe}>
      <div className='tour-card__content'>
        <div className='tour-card__preview'>
          <img
            className='tour-card__img'
            src={`${URL_FOR_IMG}/${preview && preview.path ? preview.path : null}`}
            alt={title}
          />
          {productType === TRANSFORM_TEXT.productType.EXCURSION && (
            <div className='tour-card__type'>{TRANSFORM_TEXT.productType.EXCURSION}</div>
          )}
        </div>
        <div className='tour-card__description first'>
          <h5 className='tour-card__name'>{title}</h5>
          <div className='tour-card__params'>
            <div className='tour-card__params__data'>
              <div className='tour-card__params__name'>Сезон</div>
              <div className='tour-card__params__value'>{seasonText}</div>
            </div>
            <div className='tour-card__params__data'>
              <div className='tour-card__params__name'>Группа</div>
              <div className='tour-card__params__value group'>{groupText}</div>
            </div>
            <div className='tour-card__params__data'>
              <div className='tour-card__params__name'>Возраст</div>
              <div className='tour-card__params__value age'>{ageText}</div>
            </div>
          </div>
          <p className='tour-card__text'>{shortDesc}</p>
        </div>
        <div className='tour-card__description second'>
          <div>
            <div className='tour-card__params__value price'>
             От {priceText} <RubleIcon className='ruble-icon'/>
            </div>
            <div className='tour-card__params__value duration'>
              {`${tour.duration} ${declensionNoun(tour.duration, 'day')}`}
            </div>
          </div>
          <div className='tour-card__operator'>
            <div className='tour-card__operator-photo'>{!!photoOperator && <img src={photoOperator} alt='' />}</div>
            <div className='tour-card__operator-name'>{nameOperator}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
